import {
  Box,
  Divider,
  AbsoluteCenter,
  Heading,
  Grid,
  GridItem,
  Card,
  Link,
  Image,
  Stack,
  Text,
  CardBody,
  CardFooter,
  useColorModeValue,
  useMediaQuery,
  Button,
  useColorMode,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  VStack,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { projectdetails } from "../projectdetails";

export default function Projects() {
  const bgcolor = useColorModeValue("white", "#1a202c");
  const [isSmallScreen] = useMediaQuery("(max-width: 768px)");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedProject, setSelectedProject] = useState(null);
  const { colorMode, toggleColorMode } = useColorMode();
  const shadowColor =
    colorMode === "light" ? "0 0 20px 4px white" : "0 0 20px 4px black";

  const handleOpenModal = (project) => {
    setSelectedProject(project);
    onOpen();
  };

  return (
    <section id="project-section">
      <Box
        position="relative"
        padding="10"
        mt={"100px"}
        ml={"50px"}
        mr={"50px"}
      >
        <Divider borderColor={"purple.400"} borderWidth={4} />
        <AbsoluteCenter bg={bgcolor} px="4">
          <Heading fontSize={"6xl"} color={"purple.400"}>
            PROJECTS
          </Heading>
        </AbsoluteCenter>
      </Box>
      <Grid
        templateColumns={{
          base: "1fr",
          md: "repeat(2, 1fr)",
          lg: "repeat(3, 1fr)",
        }}
        gap={8}
        ml={isSmallScreen ? "20px" : "60px"}
        mr={isSmallScreen ? "0px" : "60px"}
      >
        {projectdetails.map((project, index) => (
          <GridItem
            key={index}
            _hover={{
              transform: "scale(1.05)", // Slightly enlarges the card on hover
              boxShadow: shadowColor, // Applies a purple shadow
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
            }}
          >
            <Card
              maxW="sm"
              maxH="large"
              key={project.id}
              onClick={() => handleOpenModal(project)}
            >
              <CardBody>
                <Image
                  src={project.image}
                  alt={project.title}
                  borderRadius="lg"
                />
                <Stack mt="6" spacing="1">
                  <Heading size="md">{project.title}</Heading>

                  <Text>Tech Stack: {project.tech_stack}</Text>
                </Stack>
              </CardBody>
              <CardFooter>
                <Text
                  cursor="pointer"
                  color="purple.400"
                  textDecoration={"underline"}
                  fontSize="large"
                  onClick={() => handleOpenModal(project)}
                  mt={2}
                >
                  Know More
                </Text>
              </CardFooter>
            </Card>
          </GridItem>
        ))}
      </Grid>
      {/* Modal Component */}
      {isSmallScreen ? (
        <>
          {selectedProject && (
            <Modal isOpen={isOpen} onClose={onClose} size={"sm"}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{selectedProject.title}</ModalHeader>
                {/* <ModalCloseButton /> */}
                <ModalBody>
                  <Text fontSize={"sm"}>
                    {selectedProject.short_description}
                  </Text>
                  <Text as="b" fontSize={"sm"}>
                    Tech Stack: {selectedProject.tech_stack}
                  </Text>
                  <br></br>
                  <Text as="b" fontSize={"sm"}>
                    Project Details:
                  </Text>
                  <UnorderedList>
                    {selectedProject.full_description.map((item) => (
                      <ListItem fontSize={"sm"}>{item}</ListItem>
                    ))}
                  </UnorderedList>
                  {selectedProject.link && (
                    <Text as="b" fontSize={"sm"}>
                     Link:
                        <a
                          href={selectedProject.link}
                          target="_blank"
                          style={{ color: "#9f7aea",textDecoration:'underline' }}
                        >
                         Get a Sneak Peek
                        </a>
                    </Text>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="purple" onClick={onClose}>
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
        </>
      ) : (
        <>
          {selectedProject && (
            <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{selectedProject.title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody display="flex" gap={10}>
                  <Image
                    src={selectedProject.image}
                    alt={selectedProject.title}
                    borderRadius="lg"
                    mb="4"
                    boxSize={"sm"}
                  />
                  <Stack gap={2}>
                    {" "}
                    <Text>{selectedProject.short_description}</Text>
                    <Text as="b">Tech Stack: {selectedProject.tech_stack}</Text>
                    <Text as="b" fontSize={"lg"}>
                      Project Details:
                    </Text>
                    <UnorderedList>
                      {selectedProject.full_description.map((item) => (
                        <ListItem>{item}</ListItem>
                      ))}
                    </UnorderedList>
                    {selectedProject.link && (
                      <Text as="b" spacing={4}>
                        Link:
                        <a
                          href={selectedProject.link}
                          target="_blank"
                          style={{ color: "#9f7aea",textDecoration:'underline' }}
                        >
                         Get a Sneak Peek
                        </a>
                      </Text>
                    )}
                  </Stack>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="purple" onClick={onClose}>
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )}
        </>
      )}
    </section>
  );
}
