import { Flex, Text ,Box, Spacer,useMediaQuery} from '@chakra-ui/react'
import React from 'react'

export default function Footer() {
    const [isSmallScreen]=useMediaQuery("(max-width:768px)")
  return (
    <Flex bg={'purple.400'} mt={isSmallScreen?'500px':'150px'} p={2} >
        <Box>
            <Text fontSize={isSmallScreen?'sm':'lg'} color={'white'}>
            &copy; 2024. All rights reserved
            </Text>
        </Box>
        <Spacer/>
        <Box>
            <Text fontSize={isSmallScreen?'sm':'lg'} color={'white'}>
               Designed and Developed by Shivani❤️
            </Text>
        </Box>
        </Flex>
  )
}
