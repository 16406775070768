import { Box, Heading,Flex,useMediaQuery} from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import figmaIcon from "@iconify/icons-devicon/figma";
import html5Icon from "@iconify/icons-devicon/html5";
import css3Icon from "@iconify/icons-devicon/css3";
import tailwindcssIcon from "@iconify/icons-devicon/tailwindcss";
import nodejsWordmark from '@iconify/icons-devicon/nodejs-wordmark';
import javascriptIcon from "@iconify/icons-devicon/javascript";
import jqueryWordmark from '@iconify/icons-devicon/jquery-wordmark';
import javaWordmark from "@iconify/icons-devicon/java-wordmark";
import bootstrapIcon from "@iconify/icons-devicon/bootstrap";
import reactIcon from "@iconify/icons-devicon/react";
import mongodbIcon from "@iconify/icons-devicon/mongodb";
import expressIcon from "@iconify/icons-devicon/express";
import pythonIcon from "@iconify/icons-devicon/python";
import phpIcon from "@iconify/icons-devicon/php";
import mysqlWordmark from '@iconify/icons-devicon/mysql-wordmark';
import cIcon from '@iconify/icons-skill-icons/c';
import canvaIcon from '@iconify/icons-devicon/canva';
import React from "react";

export default function Skills() {
  const [isSmallScreen] = useMediaQuery("(max-width: 768px)");
  return (
    <section id='skills-section'>
      <Box
        ml={isSmallScreen?"10px":"50px"}
        mt={"100px"}
        borderBottom={"5px solid"}
        borderBottomColor={"purple.400"}
        mr={"20px"}
      >
        <Heading fontSize={"6xl"} color={"purple.400"}>
          TECH STACK
        </Heading>
      </Box>
         <Flex
          // justifyContent={isSmallScreen ? "center" : "space-between"}
          justifyContent={'space-between'}
        // flexDirection={isSmallScreen ? "column" : "row"}
         ml={isSmallScreen?"10px":"100px"} mr={isSmallScreen?"10px":"100px"}  mt="50px" gap={isSmallScreen ? 5: 20} mb={'20px'}>
        <Box>
        <Icon icon={html5Icon} width={isSmallScreen?"40":"100"} height={isSmallScreen?"40":"100"}/>
        </Box>
        <Box>
        <Icon icon={css3Icon} width={isSmallScreen?"40":"100"} height={isSmallScreen?"40":"100"} />
        </Box>
        <Box>
        <Icon icon={javascriptIcon} width={isSmallScreen?"40":"100"} height={isSmallScreen?"40":"100"} />
        </Box>
        <Box>
        <Icon icon={jqueryWordmark} width={isSmallScreen?"40":"100"} height={isSmallScreen?"40":"100"} />
        </Box>
        <Box>
        <Icon icon={tailwindcssIcon} width={isSmallScreen?"40":"100"} height={isSmallScreen?"40":"100"} />
        </Box>
        <Box>
        <Icon icon={bootstrapIcon} width={isSmallScreen?"40":"100"} height={isSmallScreen?"40":"100"} />
        </Box>
      </Flex>
      <Flex 
      // justifyContent={isSmallScreen ? "center" : "space-evely"}
      //   flexDirection={isSmallScreen ? "column" : "row"}
      justifyContent={'space-evenly'}
      ml={isSmallScreen?"10px":"100px"} mr={isSmallScreen?"10px":"100px"}  mt="50px" gap={isSmallScreen ? 5: 20} mb={'20px'}>
      
        <Box>
        <Icon icon={reactIcon} width={isSmallScreen?"40":"100"} height={isSmallScreen?"40":"100"} />
        </Box>
        <Box>
        <Icon icon={mongodbIcon} width={isSmallScreen?"40":"100"} height={isSmallScreen?"40":"100"} />
        </Box>
        <Box>
        <Icon icon={expressIcon} width={isSmallScreen?"40":"100"} height={isSmallScreen?"40":"100"}  />
        </Box>
        <Box>
        <Icon icon={pythonIcon} width={isSmallScreen?"40":"100"} height={isSmallScreen?"40":"100"}  />
        </Box>
        <Box>
        <Icon icon={javaWordmark} width={isSmallScreen?"40":"100"} height={isSmallScreen?"40":"100"}  />
        </Box>
      </Flex>
      <Flex 
      // justifyContent={isSmallScreen ? "center" : "space-between"}
      //   flexDirection={isSmallScreen ? "column" : "row"} 
      justifyContent={'space-between'}
      ml={isSmallScreen?"10px":"100px"} mr={isSmallScreen?"10px":"100px"}  mt="50px" gap={isSmallScreen ? 5: 20} mb={'20px'}>
        <Box>
        <Icon icon={phpIcon}width={isSmallScreen?"40":"100"} height={isSmallScreen?"40":"100"} />
        </Box>
        <Box>
        <Icon icon={mysqlWordmark} width={isSmallScreen?"40":"100"} height={isSmallScreen?"40":"100"} />
        </Box>
        <Box>
        <Icon icon={cIcon}width={isSmallScreen?"30":"80"} height={isSmallScreen?"30":"80"}  />
        </Box>
        <Box>
        <Icon icon={figmaIcon}width={isSmallScreen?"30":"80"} height={isSmallScreen?"30":"80"}  />
        </Box>
        <Box>
        <Icon icon={canvaIcon} width={isSmallScreen?"30":"80"} height={isSmallScreen?"30":"80"}  />
        </Box>
        <Box>
        <Icon icon={nodejsWordmark} width={isSmallScreen?"40":"100"} height={isSmallScreen?"40":"100"}/>
        </Box>
      </Flex>
    </section>
  );
}
