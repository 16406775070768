import todo from "./assets/todo.png"
import doctor from "./assets/doctor.png"
import chat from "./assets/chat.png"
import hrms from "./assets/hrms.png"
import uiux from "./assets/uiux1.png"
import recipe from "./assets/recipe.png"

export const projectdetails=[{
    id:"1",
    image:chat,
    title:"Talk-i-Fi (Chat App)",
    short_description:"A MERN chat application with WebSocket for real-time messaging and notifications, featuring user authentication, guest login, user and group management, and real-time updates for messages, user status, and group changes.",
    tech_stack:"MERN ,Chakra UI, Socket.io",
    full_description:[
        'User Authentication & Guest Login: Users can securely log in with authentication or join as guests for quick access.','One-on-One Chat: Users can search and chat with existing users, enabling private conversations.','Group Chat with Admin Control: Users can create group chats, with admins having exclusive rights to add or remove members.Group members, apart from the admin, have the ability to update the group name and picture','Real-Time Messaging: Instant message updates and notifications are powered by WebSocket, ensuring seamless communication. It shows when someone is typing and when not','User-Friendly Search: Effortlessly find and connect with other users for one-on-one or group interactions.','Responsive Design: The chat application is fully responsive, providing a smooth user experience on both desktop and mobile devices.'
    ],
    link:""
},
{
    id:"2",
    image:recipe,
    title:"Recipe Realms",
    short_description:"Recipe Realms is your go-to destination for discovering and sharing delicious recipes. Explore a wide variety of recipes categorized by type.Recipe Realms provides detailed recipes with video tutorials, ingredients, cooking times, and step-by-step instructions.",
    tech_stack:"React JS, Chakra UI , Django",
     full_description:['Email Authentication: Secure login via email ensures personalized access and management of recipes.','Search & Browse Recipes: Users can easily find recipes through search or explore a well-organized collection categorized by type, cuisine, and more.','Comprehensive Recipe Details: Each recipe includes a video tutorial, ingredient list, cooking time, servings, veg/non-veg classification, and step-by-step instructions with images.','Personal Recipe Management: The platform allows users to add their own unique recipes and update their own recipes, ensuring that their content is exclusive to them and doesnot interfere with others.','User-Centric Experience: The ability to manage and customize personal recipe collections sets Recipe Realms apart, providing a tailored culinary experience.'],
    link:"https://recipe-realm-v1.vercel.app/recipe/1"
},
{
    id:"3",
    image:hrms,
    title:"CodeBeat HRMS",
    short_description:"The HRMS Project automates and streamlines human resource functions, including employee records, projects, attendance, and management. With an intuitive interface and powerful reporting, it enhances efficiency, ensures compliance, and provides actionable insights for effective workforce management.",
    tech_stack:"Figma, React JS, Tailwind CSS",
     full_description:['Employee Management: Centralized platform for managing employee profiles, job roles, and employment history, ensuring streamlined HR operations.','Attendance & Leave Tracking: Automated system for tracking attendance, leave requests, and approvals, with real-time updates','Kanban Board for Task Management: Visual task management using a Kanban board, allowing HR teams to track the progress of various tasks and projects in real-time.','Employee Self-Service Portal: User-friendly interface where employees can access payslips, apply for leave, view attendance records, and update personal information.','Project Assignment & Team Allocation: Allows HR to create new projects and assign them to specific teams or groups of employees, ensuring that the right skills are matched with the right tasks across various projects.'],
    link:""
},
{
    id:"4",
    image:doctor,
    title:"Book My Doctor",
    short_description:"Book My Doctor simplifies booking medical appointments by allowing users to schedule visits with doctors online. With a user-friendly interface, patients can select available time slots, receive instant confirmation, and manage their appointments conveniently from any device, ensuring a smooth and efficient healthcare experience."
,
    tech_stack:"HTML,CSS,JS,Bootstrap,Php,MySql",
     full_description:['Multi-Role Login System: The website features three distinct logins for users, doctors, and admins, each with specific functionalities.','Appointment Booking: Users can search for doctors by city, locality, and specialization, and book appointments directly with their chosen doctor.','Patient & Doctor Details Access: After booking an appointment, both the patient and the doctor can view detailed patient information and appointment specifics.','Doctor Dashboard: Doctors have access to view patient details and manage appointments, including approving or rejecting appointment requests.','Admin Management: Admins have full control over managing doctors, including registering new doctors and the ability to create, update, and delete doctor profiles.','Profile Management: Users, doctors, and admins can all update their profile details, ensuring accurate and up-to-date information across the platform.'],
    link:""
},
{
    id:"5",
    image:todo,
    title:"Task Tracker",
    short_description:"Streamline your daily responsibilities with Task Tracker app.Boost productivity and stay organized with a clear overview of your to-dos. achieve your goals with ease and efficiency.",
    tech_stack:"React JS, Tailwind CSS",
     full_description:['Task Creation: Users can easily create new tasks and add them to their to-do list, specifying details such as task name','Task Editing: Users can modify existing tasks by updating details, ','Task Deletion: Users have the option to delete tasks that are no longer relevant or needed, keeping their to-do list clutter-free','Completion Tracking: Users can mark tasks as completed, providing a clear visual indicator of progress and accomplished tasks.'],
    link:""
},
{
    id:"6",
    image:uiux,
    title:"UI/UX Designs for Web & App",
    short_description:"I'm passionate about crafting intuitive and beautiful user experiences through thoughtful UI/UX design",
    tech_stack:"Figma",
     full_description:['I am deeply passionate about UI/UX design, continually exploring innovative ways to enhance user experiences and create visually engaging interfaces. My approach revolves around understanding user needs and translating them into intuitive, aesthetically pleasing designs. Each project is an opportunity to blend creativity with functionality, ensuring that every interaction is seamless and enjoyable.','To view my designs and see how my passion for UI/UX comes to life, please click on the link below:'],
    link:"https://scalloped-carriage-2b3.notion.site/Hi-I-m-Shivani-a69cf6e72da74c20b2eecd186473eb7f"
}
]