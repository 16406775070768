import {
  Box,
  Heading,
  Text,
  Grid,
  GridItem,
  Tabs,
  Tab,
  TabList,
  TabIndicator,
  TabPanels,
  TabPanel,
  List,
  ListItem,
  ListIcon,
  UnorderedList,
  Image,
  useMediaQuery
} from "@chakra-ui/react";
import { FaUniversity, FaSchool } from "react-icons/fa";
import React from "react";

export default function About() {
  const [isMediumScreen]=useMediaQuery("(max-width: 768px)");
  return (
    <section id='about-section'>
      <Grid templateColumns={{base:'1fr', md:'repeat(2,1fr)',lg:"repeat(3, 1fr)"}} gap={4} mt={"100px"} mr={{base:10,md:20}}>
        <GridItem>
        {isMediumScreen ? null : (
            <Box ml={'40px'} mt={'50px'}>
              <Image src='/images/about.png' borderRadius={'2xl'}></Image>
            </Box>
        )}
        </GridItem>
        <GridItem colSpan={2} ml={isMediumScreen?'20px':'none'} w={isMediumScreen?"100%":'none'}>
          <Heading fontSize="5xl" color={"purple.400"} >
            ABOUT ME
          </Heading>
          <Box borderTop={"5px solid purple"} borderTopColor={"purple.400"} >
            <Text mt={5} fontSize={"20px"} textAlign={isMediumScreen?'justify':'none'}>
              A Computer Science student with a strong background in front-end
              development and graphic design, I bring a unique blend of
              technical skills, creative vision, effective communication and
              strong collaborative abilities. I am dedicated to push boundaries
              and create innovative solutions with the latest designs and
              development trends.
            </Text>
            <Tabs position="relative" variant="unstyled">
            {isMediumScreen ? (
                <TabList>
                  <Tab color={"purple.400"} fontSize={"14px"}>
                    Education
                  </Tab>
                  <Tab color={"purple.400"} fontSize={"14px"}>
                    {isMediumScreen ? 'Language' : 'Languages Known'}
                  </Tab>
                  <Tab color={"purple.400"} fontSize={"14px"}>
                    Interests
                  </Tab>
                  <Tab color={"purple.400"} fontSize={"14px"}>
                    Certifications
                  </Tab>
                </TabList>
              ) : (
                <TabList>
                  <Tab color={"purple.400"} fontSize={"20px"}>
                    Education
                  </Tab>
                  <Tab color={"purple.400"} fontSize={"20px"}>
                    {isMediumScreen ? 'Language' : 'Languages Known'}
                  </Tab>
                  <Tab color={"purple.400"} fontSize={"20px"}>
                    Interests
                  </Tab>
                  <Tab color={"purple.400"} fontSize={"20px"}>
                    Certifications
                  </Tab>
                </TabList>
              )}
              <TabIndicator
                mt="-1.5px"
                height="2px"
                bg="purple.400"
                borderRadius="1px"
              />
              <TabPanels as="b">
                <TabPanel>
                  <List spacing={3} fontSize={'large'}>
                    <ListItem>
                      <Heading fontSize={"lg"}>
                        <ListIcon as={FaUniversity} color="purple.400" />
                        Nalanda Institute of Technology-B.Tech in Computer
                        Science| 8.36 CGPA
                      </Heading>
                    </ListItem>
                    <ListItem>
                      <Heading fontSize={"lg"}>
                        <ListIcon as={FaSchool} color="purple.400" />
                        ODM Public School - Intermediate | 93.4 % CBSE
                      </Heading>
                    </ListItem>
                    <ListItem>
                      <Heading fontSize={"lg"}>
                        <ListIcon as={FaSchool} color="purple.400" />
                        SLN Public School - Matriculation | 90 % ICSE
                      </Heading>
                    </ListItem>
                  </List>
                </TabPanel>
                <TabPanel>
                  <UnorderedList fontSize={'large'}>
                    <ListItem>English</ListItem>
                    <ListItem>Hindi</ListItem>
                    <ListItem>Odia</ListItem>
                    <ListItem>Bengali</ListItem>
                  </UnorderedList>
                </TabPanel>
                <TabPanel>
                <UnorderedList fontSize={'large'}>
                    <ListItem>Graphic Designing</ListItem>
                    <ListItem>Digital Arts</ListItem>
                    <ListItem>Calligraphy</ListItem>
                    <ListItem>Dance</ListItem>
                  </UnorderedList>
                 
                </TabPanel>
                <TabPanel>
                <UnorderedList fontSize={'large'}>
                    <ListItem>Full Stack Development- MERN Stack from Udemy</ListItem>
                    <ListItem>Web and Mobile Designer: UI/UX, Figma - Udemy</ListItem>
                    <ListItem>Digital Skills-User Experience from Accenture</ListItem>
                    <ListItem>Young Python Professional from Infosys SpringBoard</ListItem>
                    <ListItem>Basics of Python from IIT Madras -NPTEL</ListItem>
                  </UnorderedList>
                 
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </GridItem>
      </Grid>
    </section>
  );
}
