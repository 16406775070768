import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Grid,
  GridItem,
  Heading,
  ListItem,
  UnorderedList,
  useMediaQuery,
} from "@chakra-ui/react";
import React from "react";

export default function Experience() {
  const [isMediumScreen] = useMediaQuery("(max-width: 768px)");
  return (
    <section id="experience-section">
      <Grid
        templateColumns={{ md: "repeat(4,1fr)", lg: "repeat(6,1fr)" }}
        mt={"120px"}
        ml={isMediumScreen ? "10px" : "none"}
        mr={isMediumScreen ? "30px" : "5px"}
      >
        <GridItem>
          <Box
            transform={isMediumScreen ? "none" : "rotate(-90deg)"}
            borderBottom={"5px solid"}
            borderBottomColor={"purple.400"}
            mt={isMediumScreen ? "0" : "200px"}
          >
            <Heading fontSize={"6xl"} color={"purple.400"}>
              EXPERIENCE
            </Heading>
          </Box>
        </GridItem>
        <GridItem colSpan={{ md: 2, lg: 5 }} mr={{ md: "0px", lg: "10px" }}>
          {/* !New Card added */}
          <Card variant={"elevated"} mt={"10px"}>
            <CardHeader>
              <Heading color={"purple.400"}> KREATIVAN TECHNOLOGIES</Heading>
              <span style={{ float: "right" }}>Mar-Aug 2024</span>
              <Heading fontSize={"xl"} color={"purple.400"}>
                Intern as a Web Developer
              </Heading>
            </CardHeader>
            <CardBody ml={"10px"}>
              <UnorderedList fontSize={"large"}>
                <ListItem>
                  Developed a responsive architectural website for Gautam
                  Associates using React JS
                </ListItem>
                <ListItem>
                  Utilized Chakra UI for consistent and customizable UI
                  components
                </ListItem>
                <ListItem>
                  Demonstrated strong teamwork and project management skills by
                  collaborating effectively with team members to meet deadlines
                </ListItem>
              </UnorderedList>
            </CardBody>
          </Card>

          <Card variant={"elevated"} mt={"10px"}>
            <CardHeader>
              <Heading color={"purple.400"}>CODEBEAT</Heading>
              <span style={{ float: "right" }}>May-July 2023</span>
              <Heading fontSize={"xl"} color={"purple.400"}>
                Intern as a Frontend Developer
              </Heading>
            </CardHeader>
            <CardBody ml={"10px"}>
              <UnorderedList fontSize={"large"}>
                <ListItem>
                  Designed and optimized the UI/UX forthe HRMS dashboard
                  website, prioritizing a user-centric approach
                </ListItem>
                <ListItem>
                  Implemented responsive design principles to guarantee a
                  seamless and accessible user experience across various devices
                  and screen sizes
                </ListItem>
                <ListItem>
                  Translated intricate design concepts into interactive and
                  visually captivating interfaces by utilizing the power of
                  Tailwind CSS and ReactJS.
                </ListItem>
                <ListItem>
                  Integrated APIs to enhance functionality and real-time data
                  retrieval, ensuring efficient data management.
                </ListItem>
                <ListItem>
                  Provided valuable guidance and mentorship to students at
                  Silicon Institute of Technology as a web development
                  instructor
                </ListItem>
              </UnorderedList>
            </CardBody>
          </Card>
          <Card variant={"elevated"} mt={"10px"}>
            <CardHeader>
              <Heading color={"purple.400"}>
                {" "}
                Interface Software Services
              </Heading>
              <span style={{ float: "right" }}>Feb-March 2023</span>
              <Heading fontSize={"xl"} color={"purple.400"}>
                Intern as a Full Stack Developer
              </Heading>
            </CardHeader>
            <CardBody ml={"10px"}>
              <UnorderedList fontSize={"large"}>
                <ListItem>
                  Spearheaded the creation of ”Book My Doctor,” a comprehensive
                  web application facilitating medical appointment bookings
                </ListItem>
                <ListItem>
                  Led the end-to-end development process, including database
                  design, front-end implementation, back-end development, and
                  testing
                </ListItem>
                <ListItem>
                  Leveraged a robust tech stack, featuring HTML, CSS,
                  JavaScript,Bootstrap, PHP and MySQL to deliver a dynamic and
                  feature-rich web application..
                </ListItem>
              </UnorderedList>
            </CardBody>
          </Card>
        </GridItem>
      </Grid>
    </section>
  );
}
