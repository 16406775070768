import {
  Card,
  Heading,
  Box,
  Input,
  Flex,
  Textarea,
  Button,
  Text,
  Icon,
  useMediaQuery,useToast
} from "@chakra-ui/react";
import { FaEnvelope,FaLinkedin,FaInstagram,FaGithub ,FaMapMarkerAlt} from "react-icons/fa";
import React ,{useRef, useState}from "react";
import emailjs from '@emailjs/browser';

export default function Contact()
 {
  const [isSmallScreen] = useMediaQuery("(max-width: 768px)");
  const toast = useToast();
  const [loading,setLoading]=useState(false)
  const form=useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true)

    emailjs
      .sendForm(`${process.env.REACT_APP_SERVICE_ID}`, `${process.env.REACT_APP_TEMPLATE_ID}`, form.current, {
        publicKey: `${process.env.REACT_APP_PUBLIC_KEY}`,
      })
      .then(
        () => {
          toast({
            title: 'Success!',
            description: "All set! Your message is on its way!",
            status: 'success',
            duration: 5000,
            isClosable: true,
             position: 'top-right'
          });
          setLoading(false)
          console.log('SUCCESS!');
        },
        (error) => {
          toast({
            title: 'Error!',
            description: "Oops! Your message didn’t make it. Try again?",
            status: 'error',
            duration: 5000,
            isClosable: true,
             position: 'top-right'
          });
          setLoading(false)
          console.log('FAILED...', error.text);
        }
      );
    e.target.reset();
    
  };


  return (
    <section id='contact-section' >
       <Flex
        flexDirection={isSmallScreen ? "column" : "row"}
        alignItems={isSmallScreen ? "center" : "flex-start"}
        mt={isSmallScreen?"20px":"0px"}
      >
        <Box
          borderRadius={"5px"}
          width={isSmallScreen ? "95%" : "400px"}
          bg={"purple.400"}
          height={"max-content"}
          textAlign={"center"}
          mt={isSmallScreen ? "0" : "100px"}
          ml={isSmallScreen ? "0px" : "100px"}
          position={'relative'}
          zIndex={1}
          p={5}
          color={'white'}
        >
          <Heading mb={'5px'} fontSize={isSmallScreen ? '3xl' : '4xl'}>
            CONTACT ME👋🏻
          </Heading>
          <Text fontSize={isSmallScreen ? 'xl' : '2xl'} mb={'20px'}>
            Let's get connected
          </Text>
          
          <Text fontSize={'xl'}>
            <Icon as={FaInstagram} boxSize={'1em'} mr={'10px'} />_.shi._.ani_
          </Text>
          <Text fontSize={'xl'}>
            <Icon as={FaLinkedin} boxSize={'1em'} mr={'10px'} /><a href="https://www.linkedin.com/in/shivani-debnath/">Shivani Debnath</a>
          </Text>
        
          <Text fontSize={'xl'}>
            <Icon as={FaGithub} boxSize={'1em'} mr={'10px'} />github.com/Shivani-D31
          </Text>
          <Text fontSize={'xl'}>
            <Icon as={FaEnvelope} boxSize={'1em'} mr={'10px'} /><a href="https://mail.google.com/mail/?view=cm&fs=1&to=debnathshivani31@gmail.com" target="_blank">debnathshivani31@gmail.com</a>
          </Text>
        </Box>
 
        <Card
          variant={"outline"}
          // mt={isSmallScreen?"20px":"50px"}
          width={isSmallScreen ? "100%" : "62%"}
          mr={isSmallScreen ? "20px" : "20px"}
          pl={isSmallScreen?'25':'48'}
        pr={isSmallScreen ? '5' : '10'}
          ml={isSmallScreen ? "20px" : "400px"}
          position={'absolute'}
          justifyContent={'center'}
          mb={'20px'}
          mt={isSmallScreen?"300px":'50px'}
        >
          <Heading fontSize={isSmallScreen?"4xl":"6xl"} color={"purple.400"} mb={"20px"} mt={'20px'}>
          Drop me a note
          </Heading>
          <form ref={form} onSubmit={sendEmail}>
          <Input size="lg" placeholder="Your Name" mb={"20px"} name="user_name" required/>
          <Input size="lg" placeholder="Your Email" mb={"20px"} name="user_email" required/>
          <Textarea size="lg" placeholder="Your Message" mb={"20px"} name="user_msg" required></Textarea>
          <Button type="submit" size="lg" colorScheme="purple" width={'100px'} mb={'20px'}  isLoading={loading}>
            Submit
          </Button>
          </form>
        </Card>
 
      </Flex>

    </section>
  );
}
