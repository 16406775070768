import Footer from "./components/Footer";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Experience from "./pages/Experience";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import Skills from "./pages/Skills";
import Navigation from "./pages/Navigation";
import { useRef } from "react";


function App() {
  const about=useRef(null);
  const experience=useRef(null);
  const skills=useRef(null);
  const project=useRef(null);
  const contact=useRef(null);
  
  
  return (
    <div className="App">
    {/* <Navigation /> */}
    <Home aboutRef={about} exRef={experience} skillRef={skills}  projectRef={project} conRef={contact}/>
    <div id="about-section" ref={about}>
      <About />
    </div>
    <div id="experience-section" ref={experience}>
      <Experience />
    </div>
    <div id="skills-section" ref={skills}>
      <Skills />
    </div>
    <div id="projects-section" ref={project}>
      <Projects />
    </div>
    <div id="contact-section" ref={contact}>
      <Contact  />
    </div>
    <Footer />
  </div>
  );
}

export default App;
