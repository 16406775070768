import React, { useState, useEffect } from "react";
import {
  Flex,
  Button,
  Grid,
  GridItem,
  Link,
  Heading,
  Box,
  Image,
  ButtonGroup,
  useColorMode,
  IconButton,
  useMediaQuery,
} from "@chakra-ui/react";
import { FaMoon, FaSun, FaUserAlt } from "react-icons/fa";
import {
  MdWorkHistory,
  MdStackedBarChart,
  MdAssignment,
  MdPermContactCalendar,
} from "react-icons/md";

const TypingHeading = ({ text, fontSize }) => {
  const [displayText, setDisplayText] = useState("");

  useEffect(() => {
    let index = 0;
    const intervalId = setInterval(() => {
      setDisplayText(text.slice(0, index));
      index++;

      if (index > text.length) {
        clearInterval(intervalId);
      }
    }, 100);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [text]);

  return <Heading style={{ fontSize }}>{displayText}</Heading>;
};

export default function Home({
  aboutRef,
  exRef,
  skillRef,
  projectRef,
  conRef,
}) {
  const linkstyle = {
    fontSize: "20px",
    textDecoration: "none",
  };
  const hoverlink = {
    color: "purple.400",
    textDecoration: "underline",
  };
  const { colorMode, toggleColorMode } = useColorMode();
  const [isSmallScreen] = useMediaQuery("(max-width: 768px)");

  const handleScroll = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Flex
        minWidth={"max-content"}
        alignItems={"center"}
        gap={isSmallScreen ? 8 : 10}
        justifyContent={isSmallScreen ? "center" : "flex-end"}
        mr={20}
        mt={5}
        ml={isSmallScreen ? "30px" : "none"}
      >
        {isSmallScreen ? (
          <>
            <Link
              style={linkstyle}
              _hover={hoverlink}
              onClick={(e) => handleScroll(aboutRef)}
            >
              <FaUserAlt fontSize={"30px"} color="purple.500" />
            </Link>
          </>
        ) : (
          <>
            <Link
              style={linkstyle}
              _hover={hoverlink}
              onClick={(e) => handleScroll(aboutRef)}
            >
              About Me
            </Link>
          </>
        )}
        {isSmallScreen ? (
          <>
            <Link
              style={linkstyle}
              _hover={hoverlink}
              onClick={(e) => handleScroll(exRef)}
            >
              <MdWorkHistory fontSize={"36px"} color="purple.500" />
            </Link>
          </>
        ) : (
          <>
            <Link
              style={linkstyle}
              _hover={hoverlink}
              onClick={(e) => handleScroll(exRef)}
            >
              Experience
            </Link>
          </>
        )}
        {isSmallScreen ? (
          <>
            <Link
              style={linkstyle}
              _hover={hoverlink}
              onClick={(e) => handleScroll(skillRef)}
            >
              <MdStackedBarChart fontSize={"40px"} color="purple.500" />
            </Link>
          </>
        ) : (
          <>
            <Link
              style={linkstyle}
              _hover={hoverlink}
              onClick={(e) => handleScroll(skillRef)}
            >
              Skills
            </Link>
          </>
        )}
        {isSmallScreen ? (
          <>
            <Link
              style={linkstyle}
              _hover={hoverlink}
              onClick={(e) => handleScroll(projectRef)}
            >
              <MdAssignment fontSize={"35px"} color="purple.500" />
            </Link>
          </>
        ) : (
          <>
            <Link
              style={linkstyle}
              _hover={hoverlink}
              onClick={(e) => handleScroll(projectRef)}
            >
              Projects
            </Link>
          </>
        )}
        {isSmallScreen ? (
          <>
            <Link
              style={linkstyle}
              _hover={hoverlink}
              onClick={(e) => handleScroll(conRef)}
            >
              <MdPermContactCalendar fontSize={"35px"} color="purple.500" />
            </Link>
          </>
        ) : (
          <>
            <Link
              style={linkstyle}
              _hover={hoverlink}
              onClick={(e) => handleScroll(conRef)}
            >
              Contact Me
            </Link>
          </>
        )}

        <IconButton
          icon={colorMode === "light" ? <FaMoon /> : <FaSun />}
          onClick={toggleColorMode}
        ></IconButton>
      </Flex>
      <Grid
        maxW="100%" 
        overflowX={{ base: "hidden", lg: "hidden" }}
        templateColumns={{
          base: "1fr",
          md: "repeat(1, 1fr)",
          lg: "repeat(2, 1fr)",
        }}
        gap={10}

      >
        <GridItem
          w="100%"
          ml={isSmallScreen ? 0 : 180}
          mt={isSmallScreen ? 10 : 150}
          textAlign={isSmallScreen ? "center" : "justify"}
          order={isSmallScreen ? 2 : 1}
        >
          <TypingHeading
            text="HI, I AM SHIVANI"
            fontSize={isSmallScreen ? "2em" : "3.8em"}
          />
          <TypingHeading
            text="A Creative Tech Enthusiast"
            fontSize={isSmallScreen ? "xl" : "6xl"}
          />
          <ButtonGroup
            gap={5}
            mt={10}
            ml={isSmallScreen ? "10px" : "none"}
            mr={isSmallScreen ? "10px" : "none"}
          >
            <Button
              h="12"
              w="40"
              colorScheme="purple"
              onClick={(e) => {
                handleScroll(conRef);
              }}
            >
              Let's talk
            </Button>
            <a
              href="/pdf/Shivani_Debnath_Resume.pdf"
              download
              target="_blank"
            >
              <Button h="12" w="40" colorScheme="purple">
                Download CV
              </Button>
            </a>
          </ButtonGroup>
        </GridItem>

        <GridItem
          w="100%"
          ml={isSmallScreen ? 8 : 100}
          mt={100}
          order={isSmallScreen ? 1 : 2}
          mb={isSmallScreen ? "none" : "10px"}
     
        >
          <Box
            w="360px"
            h="360px"
            bg="purple.400"
            borderRadius="full"
            p="4"
            boxSize={isSmallScreen ? "300px" : "350px"}
            ml={isSmallScreen ? "40px" : "none"}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Image
              src="/images/Avatar.png"
              boxSize={isSmallScreen ? "270px" : "320px"}
              borderRadius="full"
              objectFit="cover"
            />
          </Box>
        </GridItem>
      </Grid>
    </>
  );
}
